import { useEffect, useMemo, useState } from 'react';
import type { FC } from 'react';

import { useLocation } from 'react-router-dom';

import { Home, LayoutLeft } from '@/assets/icons';
import { UveyLogo } from '@/assets/images';
import { RegularUveyIcon } from '@/assets/images/dashboard';
import {
  useAppDispatch,
  useAppFiscalYear,
  useAppSelector,
  useAppTranslation,
} from '@/hooks';
import { OperationsService } from '@/services';
import {
  setKpiValidationReceipt,
  setKpiValidationTransaction,
} from '@/store/actions/action-creators';
import { APP_VERSION } from '@/utils/constants';
import { cls } from '@/utils/funcs';

import { LockedBackdrop } from '../../Sidebar/LockedBackdrop';

interface SidebarProps {
  isOpen: boolean;
  toggleSidebar: () => void;
  locked?: boolean;
}

const Sidebar: FC<SidebarProps> = ({
  isOpen,
  toggleSidebar,
  locked = false,
}) => {
  const fiscalYear = useAppFiscalYear();
  const { t } = useAppTranslation();
  const dispatch = useAppDispatch();
  const validatedReceiptNumber = useAppSelector(
    (store) => store.global.validatedReceiptNumber
  );
  const validatedTransactionNumber: number | null = useAppSelector(
    (store) => store.global.validatedTransactionNumber
  );

  const [selectedItem, setSelectedItem] = useState('Performances');
  const location = useLocation();

  const navItems = useMemo(
    () => [
      {
        type: 'item',
        name: 'Dashboard',
        icon: (strokColor: string) => (
          <Home strokeColor={strokColor} width={16} height={16} />
        ),
        href: '/accounting/dashboard',
      },
    ],
    [validatedTransactionNumber, validatedReceiptNumber]
  );

  useEffect(() => {
    if (location.pathname.includes('settings')) {
      setSelectedItem('/settings');
    } else {
      setSelectedItem(location.pathname);
    }
  }, [location.pathname]);

  useEffect(() => {
    OperationsService.getKpiValidation(fiscalYear).then((res) => {
      dispatch(setKpiValidationReceipt(res.data?.validatedReceiptNumber));
      dispatch(
        setKpiValidationTransaction(res.data?.validatedTransactionNumber)
      );
    });
  }, [dispatch, fiscalYear]);

  return (
    <>
      {locked && <LockedBackdrop />}
      <div
        className={`relative bg-white text-white h-full pt-4 px-3 transition-all duration-300 border-r border-r-[#C8CFD5] ${
          isOpen ? 'w-64' : 'w-16'
        } hidden sm:flex flex-col`}
      >
        <div className="absolute z-[10000] top-[25px] -right-3 flex justify-end mb-2">
          <button
            onClick={toggleSidebar}
            className="text-black bg-white border border-[#C8CFD5] px-[4px] py-[6px] rounded-lg"
          >
            <div className="transition-transform duration-300 ">
              <LayoutLeft width={16} height={16} />
            </div>
          </button>
        </div>
        {/* Navigation Links */}
        <div className="flex flex-col items-center flex-grow">
          {isOpen ? (
            <img src={UveyLogo} alt="Uvey Logo" className="w-auto h-8 mb-16	" />
          ) : (
            <img
              src={RegularUveyIcon}
              alt="Uvey Icon"
              className="w-8 h-8 mb-16"
            />
          )}
          <nav className="flex w-full flex-col gap-2">
            {navItems.map(({ type, name, icon, href }) =>
              type === 'item' ? (
                <a
                  href={href}
                  key={href}
                  className={cls(
                    href === selectedItem && 'bg-[#E6EEFE]',
                    isOpen ? 'py-2 gap-4 px-4' : 'gap-0 p-2 w-fit',
                    'flex rounded-lg items-center cursor-pointer hover:bg-[#E6EEFE] transition-colors duration-200'
                  )}
                >
                  <div>
                    {icon &&
                      icon(href === selectedItem ? '#0075E7' : '#303840')}
                  </div>
                  <div className="flex justify-between w-full gap-2 items-center truncate">
                    {isOpen && (
                      <div className="flex justify-between w-full gap-2 items-center truncate text-sm">
                        <p
                          className={cls(
                            href === selectedItem
                              ? 'text-primary-default'
                              : 'text-grayscale-text-subtitle',
                            'font-normal truncate'
                          )}
                        >
                          {t(name)}
                        </p>
                      </div>
                    )}
                  </div>
                </a>
              ) : (
                <hr key={href} className="my-2" />
              )
            )}
          </nav>
        </div>
        {/* Bottom Sidebar Info */}
        <div className="relative mt-6 mb-4 ">
          <div
            className={
              isOpen
                ? 'relative z-50 flex justify-between items-center text-center pt-4'
                : 'relative z-50 flex justify-center gap-0 items-center'
            }
          >
            {isOpen && (
              <p className="text-xs text-black">{`V ${APP_VERSION}`}</p>
            )}
          </div>
          <div className="absolute bg-shape2 bg-primary bg-blur"></div>
          <div className="absolute bg-shape1 bg-secondary bg-blur"></div>
        </div>
      </div>
      {/* 796PX DISPLAY */}
      <div className="fixed bottom-0 w-full bg-white sm:hidden flex justify-around items-center  py-2 px-6 z-40 border-t border-t-[#C8CFD5]">
        {navItems.map(({ name, icon, href }) => (
          <a
            key={href}
            href={href}
            className={`${
              href === selectedItem && 'bg-blue-low-opacity'
            } flex flex-col rounded-lg items-center p-1 gap-1 `}
          >
            <div
              className={`pr-2 pl-3 ${
                href === selectedItem ? 'stroke-[#3B71FE]' : 'stroke-[#989A9E]'
              }`}
            >
              {icon && icon(href === selectedItem ? '#0075E7' : '#303840')}
            </div>
            <p
              className={`${
                href === selectedItem ? 'color-blue' : 'color-black opacity-60'
              }   font-semibold w-full text-xs`}
            >
              {t(name as string)}
            </p>
          </a>
        ))}
      </div>
    </>
  );
};

export default Sidebar;
