import type { FC } from 'react';

import { useNavigate } from 'react-router-dom';

import { useAppSelector, useAppTranslation } from '@/hooks';
import { cls } from '@/utils/funcs';

const SECTIONS = [
  { name: 'Bills', href: '', permission: 'ALL' },
  { name: 'Quotes', href: 'quotes', permission: 'ALL' },
  { name: 'Delivery Notes', href: 'delivery-notes', permission: 'ALL' },
  { name: 'Clients', href: 'client', permission: 'ALL' },
  { name: 'Products', href: 'product', permission: 'ALL' },
  { name: 'Suppliers', href: 'suppliers', permission: 'ALL' },
  { name: 'Settings', href: 'settings/bill-by-mail', permission: 'ALL' },
] as const;

interface TabsProps {
  selected: string;
}

const Tabs: FC<TabsProps> = ({ selected }) => {
  const navigate = useNavigate();
  const { t } = useAppTranslation();
  const connectedUser = useAppSelector((store) => store.global.connectedUser);

  return (
    <>
      <div className="flex">
        {SECTIONS.map(
          ({ href, name, permission }) =>
            (permission == 'ALL' ||
              connectedUser.roles.includes(permission)) && (
              <div
                key={href}
                className={cls(
                  'font-medium text-sm px-4 py-3',
                  name === selected
                    ? 'font-semibold  text-primary-default border-b-4 border-primary-default  z-20'
                    : 'text-grayscale-text-caption cursor-pointer'
                )}
                onClick={() => navigate(`/dashboard/billing/${href}`)}
              >
                {t(name)}
              </div>
            )
        )}
      </div>

      <hr className="h-0.5 bg-[#E6E8EA] -translate-y-4 z-10" />
    </>
  );
};

export default Tabs;
