import React, { type FC } from 'react';

import { type IconProps } from './IconType';

const LayoutLeft: FC<IconProps> = ({
  width = 24,
  height = 24,
  strokeWidth = 2,
  strokeColor = '#5C6B7A',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.99999 20.9999L8.99999 2.9999M21.6 17.9999L21.6 5.9999C21.6 4.01168 19.9882 2.3999 18 2.3999L5.99999 2.3999C4.01177 2.3999 2.39999 4.01168 2.39999 5.9999L2.39999 17.9999C2.39999 19.9881 4.01177 21.5999 5.99999 21.5999H18C19.9882 21.5999 21.6 19.9881 21.6 17.9999Z"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
      />
    </svg>
  );
};

export default LayoutLeft;
