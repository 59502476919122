import { docsAPI } from '@/services/apis';

import type { OrderByTypes, SortingTypes } from './models';

export default class DocService {
  static getDocuments(
    folderPath?: string,
    sortBy?: SortingTypes,
    orderBy?: OrderByTypes,
    searchQuery?: string,
    signal?: AbortSignal
  ) {
    let url = `/v1/documents`;
    const query = [];
    folderPath = parseName(folderPath);
    if (folderPath) query.push(`folder_path=${encodeURIComponent(folderPath)}`);
    if (sortBy) query.push(`sort_by=${sortBy}`);
    if (orderBy) query.push(`order_by=${orderBy}`);
    if (searchQuery) query.push(`search_query=${searchQuery}`);
    if (query.length > 0) {
      url += '?' + query.join('&');
    }
    return docsAPI.get(url, {
      signal,
    });
  }

  static uploadFile(
    file: File,
    fileName?: string,
    filePath?: string,
    signal?: AbortSignal
  ) {
    const formData = new FormData();
    formData.append('file', file);
    let url = `/v1/documents`;
    const query = [];
    fileName = parseName(fileName);
    filePath = parseName(filePath);
    if (fileName) query.push(`file_name=${encodeURIComponent(fileName)}`);
    if (filePath) query.push(`folder_path=${encodeURIComponent(filePath)}`);

    if (query.length > 0) {
      url += '?' + query.join('&');
    }
    return docsAPI.post(url, formData, {
      signal,
    });
  }

  static createFolder(folderPath: string, signal?: AbortSignal) {
    folderPath = parseName(folderPath);
    return docsAPI.post(
      `/v1/documents/folder?folder_path=${encodeURIComponent(folderPath)}`,
      null,
      { signal }
    );
  }
  static lockAndUnlockFile(
    path: string,
    objectName: string,
    signal?: AbortSignal
  ) {
    return docsAPI.put(
      `/v1/documents/lock?folder_path=${encodeURIComponent(
        path
      )}&object_name=${encodeURIComponent(objectName)}`,
      null,
      { signal }
    );
  }

  static renameFile(
    filePath: string,
    oldFileName: string,
    newFileName: string,
    signal?: AbortSignal
  ) {
    filePath = parseName(filePath);
    newFileName = parseName(newFileName);
    return docsAPI.put(
      `/v1/documents/rename?file_path=${encodeURIComponent(
        filePath
      )}&new_name=${encodeURIComponent(
        newFileName
      )}&old_name=${encodeURIComponent(oldFileName)}`,
      null,
      { signal }
    );
  }

  static moveFile(
    filePath: string,
    fileName: string,
    newFolderPath: string,
    signal?: AbortSignal
  ) {
    filePath = parseName(filePath);
    fileName = parseName(fileName);
    newFolderPath = parseName(newFolderPath);
    return docsAPI.put(
      `/v1/documents/move?old_folder_path=${encodeURIComponent(
        filePath
      )}&file_name=${encodeURIComponent(
        fileName
      )}&new_folder_path=${encodeURIComponent(newFolderPath)}`,
      null,
      { signal }
    );
  }

  static renameFolder(
    folderPath: string,
    oldName: string,
    newName: string,
    signal?: AbortSignal
  ) {
    folderPath = parseName(folderPath);
    newName = parseName(newName);
    oldName = parseName(oldName);
    return docsAPI.put(
      `/v1/documents/folder/rename?folder_path=${encodeURIComponent(
        folderPath
      )}&new_name=${encodeURIComponent(newName)}&old_name=${encodeURIComponent(
        oldName
      )}`,
      null,
      { signal }
    );
  }

  static moveFolder(
    folderPath: string,
    folderName: string,
    newPath: string,
    signal?: AbortSignal
  ) {
    folderPath = parseName(folderPath);
    folderName = parseName(folderName);
    newPath = parseName(newPath);
    return docsAPI.put(
      `/v1/documents/folder/move?old_folder_path=${encodeURIComponent(
        folderPath
      )}&folder_name=${encodeURIComponent(
        folderName
      )}&new_folder_path=${encodeURIComponent(newPath)}`,
      null,
      { signal }
    );
  }

  static deleteFile(filePath: string, signal?: AbortSignal) {
    filePath = parseName(filePath);
    return docsAPI.delete(
      `/v1/documents?folder_path=${encodeURIComponent(filePath)}`,
      { signal }
    );
  }

  static deleteFolder(folderPath: string, signal?: AbortSignal) {
    folderPath = parseName(folderPath);
    return docsAPI.delete(
      `/v1/documents/folder?folders_path=${encodeURIComponent(folderPath)}`,
      {
        signal,
      }
    );
  }
  static downloadFolder(folderPath: string, signal?: AbortSignal) {
    folderPath = parseName(folderPath);
    return docsAPI.get(
      `/v1/documents/folder/download?folder_path=${encodeURIComponent(
        folderPath
      )}`,
      {
        responseType: 'blob',
        signal,
      }
    );
  }
  static downloadFile(
    filePath: string,
    fileName: string,
    signal?: AbortSignal
  ) {
    filePath = parseName(filePath);
    fileName = parseName(fileName);
    return docsAPI.get(
      `/v1/documents/download?path_folder=${encodeURIComponent(
        filePath
      )}&file_name=${encodeURIComponent(fileName)}`,
      {
        responseType: 'blob',
        signal,
      }
    );
  }
}

function parseName(name: string | undefined): string {
  if (!name) return '';
  // regex to remove all special characters except for - and _ and / and . and space and numbers and letters
  return name.replace(/[^a-zA-Z0-9-_/. ]/g, '');
}
