import React, { type FC } from 'react';

import { type IconProps } from './IconType';

const Bank: FC<IconProps & { className?: string }> = ({
  width = 24,
  height = 24,
  strokeWidth = 2,
  strokeColor = '#5C6B7A',
  className,
}) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.00002 15V12.3313M9.33336 15V12.3313M14.6667 15V12.3313M20 15V12.3313M2.40002 18.4H21.6V21.6H2.40002V18.4ZM2.40002 8.79999V6.66666L11.6055 2.39999L21.6 6.66666V8.79999H2.40002Z"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Bank;
