import { type FC, useEffect, useMemo, useState } from 'react';

import {
  Close,
  DashboardOutlined,
  ExitToAppOutlined,
  Menu,
  Percent,
  SettingsOutlined,
} from '@mui/icons-material';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import { Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { CompaniesIcon } from '@/assets/images';
import { Image } from '@/components/global';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { AuthService, SubscriptionsService } from '@/services';
import {
  setCompanySubscription,
  setConnectedUser,
  setPlanInfo,
} from '@/store/actions/action-creators';
import { type CompanySubscription } from '@/store/reducers';
import { APP_VERSION } from '@/utils/constants';

import { Notification, Profile } from '../../Navbar';
import ExpiredPlanButton from '../../Navbar/ExpiredPlanButton';

const MILLISECONDS_IN_DAY = 1000 * 60 * 60 * 24;

interface NavbarProps {}

const Navbar: FC<NavbarProps> = () => {
  const dispatch = useAppDispatch();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const companySubscription: CompanySubscription | null = useAppSelector(
    (store) => store.global.companySubscription
  );

  const { t } = useTranslation();
  const navigate = useNavigate();

  const { subscriptionTimeLeft, subscriptionDaysLeft } = useMemo(() => {
    let subscriptionTimeLeft = -1;
    if (companySubscription && companySubscription.endDate)
      subscriptionTimeLeft =
        (new Date(companySubscription.endDate).getTime() - Date.now()) /
        MILLISECONDS_IN_DAY;

    return {
      subscriptionTimeLeft,
      subscriptionDaysLeft:
        subscriptionTimeLeft > 0 ? Math.ceil(subscriptionTimeLeft) : 0,
    };
  }, [companySubscription]);

  const { isAlmostExpired, buttonLabel } = useMemo(() => {
    let buttonLabel = t('Subscription Days Left', {
      days: subscriptionDaysLeft,
    });

    if (subscriptionTimeLeft <= 1 && subscriptionTimeLeft > 0)
      buttonLabel = t('Expires in less than 1 day');

    return {
      isAlmostExpired: subscriptionTimeLeft >= 0 && subscriptionTimeLeft <= 7,
      buttonLabel,
    };
  }, [subscriptionTimeLeft]);

  const handleSignOut = () => {
    dispatch(setConnectedUser(null));
    dispatch(setCompanySubscription(null));
    AuthService.removeRefreshTokenCookie().then(() => {
      localStorage.removeItem('access-token');
    });
    navigate('/');
  };

  useEffect(() => {
    //! Keep it just in case
    // setPlanInfoStatus(PlanInfoStatus.IN_PROGRESS);

    SubscriptionsService.getPlanInfo(companySubscription?.planId ?? '').then(
      ({ data }) => {
        // setPlanInfoStatus(PlanInfoStatus.SUCCESS);
        dispatch(setPlanInfo(data));
      }
    );
  }, []);

  const runAndHideMenu = (callback: any) => {
    callback();
    setIsMenuOpen(false);
  };

  const menuItems = [
    {
      type: 'item',
      icon: <DashboardOutlined className="text-xs" />,
      label: t('Dashboard'),
      onClick: () => runAndHideMenu(() => navigate('/dashboard')),
    },
    {
      type: 'item',
      icon: <NotificationsOutlinedIcon />,
      label: t('Notification'),
      onClick: () => runAndHideMenu(() => navigate('/dashboard/notifications')),
    },
    {
      type: 'item',
      icon: <Percent />,
      label: t('Accounting'),
      onClick: () =>
        runAndHideMenu(() => navigate('/dashboard/accounting/export-data')),
    },
    {
      type: 'item',
      icon: <SettingsOutlined className="text-xs" />,
      label: t('Settings'),
      onClick: () => runAndHideMenu(() => navigate('/settings/profile')),
    },
  ];

  return (
    <>
      {/* //? Default Navbar */}
      <div className="sticky border-b border-b-[#C8CFD5] top-0 z-40 flex justify-between items-center px-6 py-4 w-full h-[90px] bg-white drop-shadow-1">
        <div>
          <h1 className="font-bold text-2xl	mb-1 text-[#040506]">Dashboard</h1>
        </div>
        <div className="flex grow">
          <Tooltip placement="right" title={'Uvey'}>
            <button
              onClick={() => {
                navigate('/dashboard');
              }}
              className="ml-14"
            >
              {/* <Logo /> */}
            </button>
          </Tooltip>
        </div>

        <div className="flex justify-center grow !items-stretch max-w-[700px]"></div>
        <div className="flex gap-5 items-center">
          {isAlmostExpired && (
            <ExpiredPlanButton
              heading={t('Renew My Subscription')}
              buttonLabel={buttonLabel}
              description={t('Subscription Expiry Approaching')}
            />
          )}

          <Notification />
          <Profile />
        </div>
      </div>
      {/* //? Navbar Menu for mobile */}
      <div className="min-[800px]:hidden max-[800px]:flex fixed top-0 z-50 items-center justify-between w-full pt-4 pb-4 pr-6 bg-first scroll-bar-style z-index">
        <div className="flex grow !items-stretch">
          <a
            href={'/accounting/dashboard'}
            className="cursor-pointer py-3 rounded-lg px-4 bg-transparent text-[#8D98AF] border-solid border-2 border-gray-200"
          >
            <Tooltip placement="top" title={t('Go to dashboard')}>
              <div className="hover:scale-125 duration-300 ease-in-out">
                <Image
                  url={CompaniesIcon}
                  alt={'companies dashboard'}
                  clsContainer="h-[30px] min-w-[24px] w-[24px]"
                />
              </div>
            </Tooltip>
          </a>
        </div>

        <button
          onClick={() => setIsMenuOpen(true)}
          className="flex items-center justify-end"
        >
          <Menu className="cursor-pointer" />
        </button>
      </div>
      {/* //? Navbar (Sidebar) for mobile */}
      <div
        style={{
          right: isMenuOpen ? 0 : '-100%',
        }}
        className="h-full  bg-first z-50 w-4/5 fixed flex flex-col justify-between duration-300"
      >
        <div className="flex items-center justify-between w-full absolute top-3">
          <button
            onClick={() => setIsMenuOpen(false)}
            className="w-full flex items-center justify-end p-6 "
          >
            <Close className="cursor-pointer" />
          </button>
        </div>

        <div className="flex flex-col gap-2 mt-28">
          {menuItems.map((item, idx) => (
            <div key={idx}>
              <div
                className="flex cursor-pointer text-[#797e8a] gap-2 font-bold hover:bg-[#eff3fd] px-5 py-3 duration-300 whitespace-nowrap"
                onClick={item.onClick}
              >
                {item.icon}
                <p>{item.label}</p>
              </div>
              <hr />
            </div>
          ))}
        </div>

        <div className="mb-8">
          <div className="flex w-full p-6 items-start justify-between relative ">
            <button
              onClick={() => runAndHideMenu(() => handleSignOut())}
              className="flex cursor-pointer text-[#797e8a] gap-2 font-bold "
            >
              <ExitToAppOutlined className="text-xs rotate-180" />
              <span>{t('Sign Out')}</span>
            </button>
          </div>
          <h4 className="text-center pt-4 text-black pb-4">V {APP_VERSION}</h4>
        </div>
      </div>
    </>
  );
};

export default Navbar;
