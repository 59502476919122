import type { FC } from 'react';

import { useNavigate } from 'react-router-dom';

import { useAppSelector, useAppTranslation } from '@/hooks';
import { cls } from '@/utils/funcs';

interface TabsProps {
  selected: string;
}

const Tabs: FC<TabsProps> = ({ selected }) => {
  const navigate = useNavigate();
  const { t } = useAppTranslation();
  const connectedUser = useAppSelector((store) => store.global.connectedUser);

  const SECTIONS = [
    // { name: t('EXPORT_DATA'), href: 'export-data', permission: 'ALL' },
    {
      name: 'IMPORT_BOOKENTRIES',
      href: 'bookentries-import',
      permission: 'ACCOUNTANT',
    },
    { name: 'ACCOUNTING_PLAN', href: 'accounting-plan', permission: 'ALL' },
    // { name: 'FISCAL_YEARS', href: 'fiscal-year', permission: 'ALL' },
    // {
    //   name: 'IMPORT_ACCOUNTING_PLAN',
    //   href: 'accounting-plan-import',
    //   permission: 'ACCOUNTANT',
    // },
    { name: 'Book entries', href: 'book-entries', permission: 'ACCOUNTANT' },
    { name: 'Ledger', href: 'ledger', permission: 'ACCOUNTANT' },
    {
      name: 'CATEGORY_BALANCE',
      href: 'category-balance',
      permission: 'ACCOUNTANT',
    },
    {
      name: 'FINANCIAL_STATEMENT',
      href: 'financial-statement',
      permission: 'ACCOUNTANT',
    },
    {
      name: 'Settings',
      href: 'settings-accountant/fiscal-year',
      permission: 'ALL',
    },
  ] as const;

  return (
    <>
      <div className="col-span-12 flex">
        {SECTIONS.map(
          ({ href, name, permission }) =>
            (permission == 'ALL' ||
              connectedUser.roles.includes(permission)) && (
              <div
                key={href}
                className={cls(
                  'font-normal px-4 py-3 h-full flex justify-center items-center w-full text-center first:rounded-tl-lg last:rounded-tr-lg',
                  name === selected
                    ? 'text-primary-default bg-primary-subtle border-b-[3px] border-primary-default z-20'
                    : 'text-grayscale-text-caption cursor-pointer'
                )}
                onClick={() => navigate(`/dashboard/accounting/${href}`)}
              >
                {t(name)}
              </div>
            )
        )}
      </div>

      <hr className="col-span-12 h-[1px] bg-[#C8CFD5] -translate-y-4 z-10 w-full" />
    </>
  );
};

export default Tabs;
