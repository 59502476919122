import { type FC, useEffect, useMemo, useState } from 'react';

import {
  Close,
  DashboardOutlined,
  ExitToAppOutlined,
  Menu,
  Percent,
  SettingsOutlined,
} from '@mui/icons-material';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import { Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { ChevronRight } from '@/assets/icons';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { AuthService, SubscriptionsService } from '@/services';
import {
  setCompanySubscription,
  setConnectedUser,
  setPlanInfo,
} from '@/store/actions/action-creators';
import { type CompanySubscription } from '@/store/reducers';
import { APP_VERSION } from '@/utils/constants';

import {
  Company,
  LangSelector, // LangSelector,
  Notification, // PageCounter,
  Profile,
} from '.';
import ExpiredPlanButton from './ExpiredPlanButton';

const MILLISECONDS_IN_DAY = 1000 * 60 * 60 * 24;

interface NavbarProps {}

const Navbar: FC<NavbarProps> = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const currentPaths = location.pathname.substring(1).split('/');

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const companySubscription: CompanySubscription | null = useAppSelector(
    (store) => store.global.companySubscription
  );

  const { t } = useTranslation();
  const navigate = useNavigate();

  const { subscriptionTimeLeft, subscriptionDaysLeft } = useMemo(() => {
    let subscriptionTimeLeft = -1;
    if (companySubscription && companySubscription.endDate)
      subscriptionTimeLeft =
        (new Date(companySubscription.endDate).getTime() - Date.now()) /
        MILLISECONDS_IN_DAY;

    return {
      subscriptionTimeLeft,
      subscriptionDaysLeft:
        subscriptionTimeLeft > 0 ? Math.ceil(subscriptionTimeLeft) : 0,
    };
  }, [companySubscription]);

  const { isAlmostExpired, buttonLabel } = useMemo(() => {
    let buttonLabel = t('Subscription Days Left', {
      days: subscriptionDaysLeft,
    });

    if (subscriptionTimeLeft <= 1 && subscriptionTimeLeft > 0)
      buttonLabel = t('Expires in less than 1 day');

    return {
      isAlmostExpired: subscriptionTimeLeft >= 0 && subscriptionTimeLeft <= 7,
      buttonLabel,
    };
  }, [subscriptionTimeLeft]);

  const handleSignOut = () => {
    dispatch(setConnectedUser(null));
    dispatch(setCompanySubscription(null));
    AuthService.removeRefreshTokenCookie().then(() => {
      localStorage.removeItem('access-token');
    });
    navigate('/');
  };

  useEffect(() => {
    //! Keep it just in case
    // setPlanInfoStatus(PlanInfoStatus.IN_PROGRESS);

    SubscriptionsService.getPlanInfo(companySubscription?.planId ?? '').then(
      ({ data }) => {
        // setPlanInfoStatus(PlanInfoStatus.SUCCESS);
        dispatch(setPlanInfo(data));
      }
    );
  }, []);

  const runAndHideMenu = (callback: any) => {
    callback();
    setIsMenuOpen(false);
  };

  const menuItems = [
    {
      type: 'item',
      icon: <DashboardOutlined className="text-xs" />,
      label: t('Dashboard'),
      onClick: () => runAndHideMenu(() => navigate('/dashboard')),
    },
    {
      type: 'item',
      icon: <NotificationsOutlinedIcon />,
      label: t('Notification'),
      onClick: () => runAndHideMenu(() => navigate('/dashboard/notifications')),
    },
    {
      type: 'item',
      icon: <Percent />,
      label: t('Accounting'),
      onClick: () =>
        runAndHideMenu(() => navigate('/dashboard/accounting/export-data')),
    },
    {
      type: 'item',
      icon: <SettingsOutlined className="text-xs" />,
      label: t('Settings'),
      onClick: () => runAndHideMenu(() => navigate('/settings/profile')),
    },
  ];

  return (
    <>
      {/* //? Default Navbar */}
      <div className="sticky h-[90px] border-b border-b-[#C8CFD5] top-0 z-40 flex justify-between items-center px-6 py-4 w-full bg-white drop-shadow-1 ">
        <div>
          <h1 className="font-bold text-2xl	mb-1 text-[#040506]">
            {t(currentPaths[1] ? currentPaths[1] : currentPaths[0])}
          </h1>
          {/* //! Keep it just in case */}
          <div>
            <div className="flex items-center">
              <p className="font-normal text-sm	text-[#5C6B7A]">
                {t(currentPaths[2])}
              </p>
              {currentPaths[3] && (
                <div className="flex items-center">
                  <span>
                    <ChevronRight
                      width={16}
                      height={16}
                      strokeColor="#5C6B7A"
                    />
                  </span>
                  <span className="font-normal text-sm text-[#5C6B7A]">
                    {t(currentPaths[3])}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="flex grow">
          <Tooltip placement="right" title={'Uvey'}>
            <button
              onClick={() => {
                navigate('/dashboard');
              }}
              className="ml-14"
            ></button>
          </Tooltip>
        </div>

        <div className="flex justify-center grow !items-stretch max-w-[700px]"></div>
        <div className="flex items-center gap-5">
          {isAlmostExpired && (
            <ExpiredPlanButton
              heading={t('Renew My Subscription')}
              buttonLabel={buttonLabel}
              description={t('Subscription Expiry Approaching')}
            />
          )}
          <LangSelector position="-bottom-[120px]" />
          <Company />
          <Notification />
          <Profile />
        </div>
      </div>
      {/* //? Navbar Menu for mobile */}
      <div className="min-[800px]:hidden max-[800px]:flex gap-4 fixed top-0 z-50 items-center justify-between w-full px-6 py-4 bg-first scroll-bar-style">
        <div className="flex grow !items-stretch">
          <div className="flex items-center justify-between min-w-full">
            <h1 className="font-bold text-2xl	mb-1 text-[#040506]">
              {t(currentPaths[1] ? currentPaths[1] : currentPaths[0])}
            </h1>
            <div className="flex items-center gap-4">
              <Company />
              <Notification />
            </div>
          </div>
        </div>

        <button
          onClick={() => setIsMenuOpen(true)}
          className="flex items-center justify-end"
        >
          <Menu className="cursor-pointer" />
        </button>
      </div>
      {/* //? Navbar (Sidebar) for mobile */}
      <div
        style={{
          right: isMenuOpen ? 0 : '-100%',
        }}
        className="fixed z-50 flex flex-col justify-between w-4/5 h-full duration-300 bg-first"
      >
        <div className="absolute flex items-center justify-between w-full top-3">
          {/* //! Keep it just in case  */}

          <button
            onClick={() => setIsMenuOpen(false)}
            className="flex items-center justify-end w-full p-6"
          >
            <Close className="cursor-pointer" />
          </button>
        </div>

        <div className="flex flex-col gap-2 mt-28">
          {menuItems.map((item, idx) => (
            <div key={idx}>
              <div
                className="flex cursor-pointer text-[#797e8a] gap-2 font-bold hover:bg-[#eff3fd] px-5 py-3 duration-300 whitespace-nowrap"
                onClick={item.onClick}
              >
                {item.icon}
                <p>{item.label}</p>
              </div>
              <hr />
            </div>
          ))}
        </div>

        <div className="mb-8">
          <div className="relative flex items-start justify-between w-full p-6 ">
            <button
              onClick={() => runAndHideMenu(() => handleSignOut())}
              className="flex cursor-pointer text-[#797e8a] gap-2 font-bold "
            >
              <ExitToAppOutlined className="text-xs rotate-180" />
              <span>{t('Sign Out')}</span>
            </button>
          </div>
          <h4 className="pt-4 pb-4 text-center text-black">V {APP_VERSION}</h4>
        </div>
      </div>
    </>
  );
};

export default Navbar;
