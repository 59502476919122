import { lazy } from 'react';

import { Navigate, Route } from 'react-router-dom';

import { SettingsLayout } from '@/components/layouts';
import { AuthGuard, SubscriptionGuard } from '@/lib/guards';
import OcrManagementGuard from '@/lib/guards/OcrManagementGuard';

import type { RouteType } from '.';

const Enterprise = lazy(() => {
  return new Promise((resolve) => {
    // @ts-expect-error

    setTimeout(() => resolve(import('@/pages/settings/Enterprise')), 500);
  });
});

const OcrManagement = lazy(() => {
  return new Promise((resolve) => {
    // @ts-expect-error
    setTimeout(() => resolve(import('@/pages/settings/OcrManagement')), 500);
  });
});

const AddPages = lazy(() => {
  return new Promise((resolve) => {
    // @ts-expect-error
    setTimeout(() => resolve(import('@/pages/settings/AddPages')), 500);
  });
});

const Profile = lazy(() => {
  return new Promise((resolve) => {
    // @ts-expect-error
    setTimeout(() => resolve(import('@/pages/settings/Profile')), 500);
  });
});

//! keep it just in case
// const Subscription = lazy(() => {
//   return new Promise((resolve) => {
//     // @ts-expect-error
//     setTimeout(() => resolve(import('@/pages/settings/Subscription')), 500);
//   });
// });

const Team = lazy(() => {
  return new Promise((resolve) => {
    // @ts-expect-error
    setTimeout(() => resolve(import('@/pages/settings/Team')), 500);
  });
});

const Password = lazy(() => {
  return new Promise((resolve) => {
    // @ts-expect-error
    setTimeout(() => resolve(import('@/pages/settings/Password')), 500);
  });
});

const ExportData = lazy(() => {
  return new Promise((resolve) => {
    // @ts-expect-error
    setTimeout(() => resolve(import('@/pages/settings/export')), 500);
  });
});

const outletElements = [
  {
    href: '',
    element: <Navigate to="profile" />,
  },
  {
    href: 'profile',
    element: <Profile />,
  },

  {
    href: 'add-pages',
    element: <AddPages />,
  },
  {
    href: 'enterprise',
    element: <Enterprise />,
  },
  {
    href: 'team',
    element: <Team />,
  },
  //! Keep it just in case
  // {
  //   href: 'subscription',
  //   element: <Subscription />,
  // },
  {
    href: 'password',
    element: <Password />,
  },
  {
    href: 'export-data',
    element: <ExportData />,
  },
  {
    href: 'ocr-management',
    element: <OcrManagement />,
  },
];

const routes: RouteType[] = [
  {
    children: (
      <>
        {outletElements.map(({ element, href }, idx) => {
          if (href === 'ocr-management' || href === 'add-pages') {
            return (
              <Route
                key={idx}
                element={<OcrManagementGuard>{element}</OcrManagementGuard>}
                path={href}
              />
            );
          }

          return <Route key={idx} element={element} path={href} />;
        })}
      </>
    ),
    element: (
      <AuthGuard>
        <SubscriptionGuard>
          <SettingsLayout />
        </SubscriptionGuard>
      </AuthGuard>
    ),
    path: 'settings',
  },
];

export default routes;
