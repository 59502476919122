import React, { type FC, useEffect, useRef, useState } from 'react';

import { Chat as MarkChatRead, Send } from '@mui/icons-material';
import { Popover } from '@mui/material';
import moment from 'moment';

import { MessageCircle } from '@/assets/icons';
import { userAvatar } from '@/assets/images';
import { Button, ConfirmationModal, Image, Input } from '@/components/global';
import { useAppTranslation } from '@/hooks';
import { type Chat, SaasService } from '@/services';
import ChatService from '@/services/ChatService';

export interface DiscussionPopoverProps {
  connectedUser: any;
  loit: string | undefined;
  lot: string;
  OneReceipt?: boolean;
  chat?: Chat;
  isTable?: boolean;
}

export interface Message {
  id?: string;
  who: string;
  when: Date;
  what: string;
}

export interface Member {
  addedAt: string;
  addedBy: string;
  id: string;
  lastAccessAt: string;
  roles: string[];
  user: user;
}

export interface user {
  activated: boolean;
  avatar: string;
  companyId: string;
  createdAt: string;
  email: string;
  id: string;
  lastLoginAt: string;
  locked: boolean;
  name: string;
  phoneNumber: string;
  roles: string[];
  surname: string;
}

const DiscussionPopover: FC<DiscussionPopoverProps> = ({
  loit,
  lot,
  connectedUser,
  OneReceipt,
  chat: initialChat,
  isTable = false,
}) => {
  const { t } = useAppTranslation();
  const [inputValue, setInputValue] = useState<string>('');
  const [chatData, setchatData] = useState<Message[]>([]);
  const [Members, setMembers] = useState<Member[]>([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [idChat, setIdChat] = useState<string>('');
  const [oneChat, setOneChat] = useState<Chat>(
    initialChat || (null as unknown as Chat)
  );
  const [isArchivedChat, setIsArchivedChat] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  DiscussionPopover.defaultProps = {
    OneReceipt: false,
  };
  const chatContainerRef = useRef<HTMLDivElement>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  useEffect(() => {
    const chatContainer = chatContainerRef.current;
    if (chatContainer) {
      chatContainer.scrollTop = chatContainer.scrollHeight;
    }
  });

  useEffect(() => {
    setOneChat(null as unknown as Chat);
    setchatData([]);
    if (initialChat) {
      setOneChat(initialChat);
      setchatData(initialChat.messages);
      setIdChat(initialChat.id);
      if (
        initialChat?.archivedByRoles?.includes('OWNER')
          ? connectedUser.roles.includes('OWNER')
          : initialChat?.archivedByRoles?.includes('ACCOUNTANT') &&
            connectedUser.roles.includes('ACCOUNTANT')
      ) {
        setIsArchivedChat(true);
      }
    }
  }, [loit]);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
    if (!oneChat) {
      ChatService.createChat(loit, lot).then((response) => {
        setOneChat(response.data);
        setchatData(response.data.messages || []);
        setIdChat(response.data.id);
      });
    }

    SaasService.getMembersByCompanyId().then((response) => {
      setMembers(response.data);
    });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      addMessage();
    }
  };

  const addMessage = () => {
    if (inputValue.trim() === '') {
      return;
    }
    const newMessage: Message = {
      who: connectedUser?.id || 'new_message_id',
      when: new Date(),
      what: inputValue,
    };
    if (oneChat?.chatStatus === 'ARCHIVED') {
      ChatService.unArchiveChat(idChat).then((response) => {
        setOneChat(response.data);
        setIsArchivedChat(false);
      });
    }
    setchatData((prevChat) => [...prevChat, newMessage]);
    ChatService.addMessage(idChat, inputValue, []);

    setInputValue('');
  };

  const markChatRead = async (id: string) => {
    if (
      oneChat?.messages[oneChat?.messages.length - 1].who !==
        connectedUser.id &&
      !connectedUser.roles.every((item: string) =>
        oneChat?.readByRoles?.includes(item)
      )
    ) {
      const response = await ChatService.readChat(id);
      setOneChat((prevChat) => ({
        ...prevChat,
        readByRoles: response.data.readByRoles,
      }));
    }
  };

  const getUserById = (userId: string) => {
    const user = Members.find((member) => member.user.id === userId);
    return user ? user.user : undefined;
  };

  const archiveChat = () => {
    ChatService.archiveChat(idChat).then((response) => {
      setOneChat(response.data);
      setIsArchivedChat(true);
    });
  };

  return (
    <div>
      <div
        onClick={(e) => {
          e.cancelable = true;
          e.stopPropagation();
          handleClick(e);
          markChatRead(idChat);
        }}
        className={
          isTable
            ? `flex items-center justify-center p-[6px] rounded-full cursor-pointer w-fit h-[28px] ${
                OneReceipt
                  ? 'border-[2px] bg-transparent border-[#0075E7] hover:opacity-80 transition-opacity duration-200'
                  : 'bg-transparent'
              }`
            : `flex items-center justify-center p-2 rounded-lg cursor-pointer ${
                OneReceipt
                  ? 'border-[2px] bg-transparent border-[#0075E7] hover:opacity-80 transition-opacity duration-200'
                  : 'bg-sec'
              }`
        }
      >
        <div className="relative text-[16px]">
          {isArchivedChat ? (
            <MarkChatRead
              htmlColor={OneReceipt ? '#0075E7' : '#454545'}
              fontSize="inherit"
            />
          ) : oneChat &&
            chatData.length > 0 &&
            (chatData[chatData.length - 1].who === connectedUser.id ||
              connectedUser.roles.some((item: string) =>
                oneChat.readByRoles?.includes(item)
              )) ? (
            <div className="relative">
              <MessageCircle
                width={16}
                height={16}
                strokeColor={OneReceipt ? '#0075E7' : '#454545'}
              />
              <div className="absolute top-0 right-0">
                <div className="w-[8px] h-[8px] bg-green-600 rounded-full"></div>
              </div>
            </div>
          ) : oneChat &&
            !connectedUser.roles.every((item: string) =>
              oneChat.readByRoles?.includes(item)
            ) &&
            chatData.length > 0 ? (
            <div className="relative">
              <MessageCircle
                width={16}
                height={16}
                strokeColor={OneReceipt ? '#0075E7' : '#454545'}
              />
              <div className="absolute top-0 right-0">
                <div className="rounded-full w-[8px] h-[8px] bg-red-500 items-center flex justify-center">
                  <div className=" animate-ping w-[10px] h-[8px] rounded-full bg-red-400 opacity-75"></div>
                </div>
              </div>
            </div>
          ) : (
            <MessageCircle
              strokeColor={OneReceipt ? '#0075E7' : '#454545'}
              width={16}
              height={16}
            />
          )}
        </div>
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={(e: React.MouseEvent<HTMLButtonElement>) => {
          e.cancelable = true;
          e.stopPropagation();
          handleClose();
        }}
        onClick={(e) => {
          e.cancelable = true;
          e.stopPropagation();
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        sx={{
          '& .MuiPopover-paper': {
            marginLeft: '8px',
            width: '100%',
            maxWidth: 400,
            borderRadius: '4px',
          },
        }}
      >
        <div className="min-h-[200px] max-h-[350px]py-4 flex flex-col ">
          <div className="flex items-center justify-between px-4 py-1">
            <h2 className="my-2 text-sm font-bold">{t('Discussion')}</h2>
            {chatData.length > 0 && !isArchivedChat && (
              <Button
                className="w-[75px] h-[30px] rounded-2xl bg-[#d6e1fd] flex items-center justify-center"
                onClick={() => {
                  setIsOpen(true);
                }}
                label={t('Archive')}
                size="small"
              />
            )}
          </div>
          <hr className="w-full" />

          <div
            ref={chatContainerRef}
            className="min-h-[200px] max-h-[350px] overflow-y-scroll py-4 flex flex-col "
          >
            {chatData?.map((el) => (
              <div className={`flex flex-col gap-2 px-2 py-3`}>
                <div className="flex flex-col ">
                  <div className="flex items-start w-full gap-2">
                    <div className="w-[40px] h-[30px] rounded-full object-cover">
                      <Image
                        alt="fr"
                        url={
                          (connectedUser.id === el.who
                            ? connectedUser.avatar
                            : getUserById(el.who)?.avatar) || userAvatar
                        }
                        cls="w-[30px] h-[30px] rounded-full object-cover"
                      />
                    </div>
                    <div className="w-full">
                      <div className="flex items-center gap-3">
                        <span className="font-bold text-md">
                          {getUserById(el.who)?.name || 'Unknown User'}
                        </span>

                        <p className="text-xs text-slate-500">
                          {moment(el.when).format('DD MMM YYYY')}
                        </p>
                      </div>
                      <div
                        className={`flex items-center p-2 w-full rounded-lg rounded-tl-none ${
                          connectedUser.id === el.who && 'bg-slate-100'
                        }`}
                      >
                        <p className="text-slate-700 text-sm max-w-[300px] break-all ">
                          {el.what}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {/*//! Keep it */}
          {/* <div className="flex flex-col items-center justify-center gap-6">
                      <img
                        className="h-[100px]"
                        alt="comments"
                        src={emptyComments}
                      />

                      <p className="max-w-[250px] text-center text-[#8D98AF]">
                        There's no discussion yet, start a discussion now
                      </p>
                    </div> */}
        </div>
        <hr />
        <div className="flex items-center justify-center p-4 space-x-3">
          <div className="w-[45px] h-[30px]">
            <Image
              alt="user avatar"
              url={connectedUser.avatar || userAvatar}
              cls="w-[30px] h-[30px] rounded-full object-cover"
            />
          </div>
          <Input
            cls="w-full relative"
            placeholder={t('WRITE_A_MESSAGE')}
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyDown={(e) => {
              e.stopPropagation();
              handleKeyPress(e);
            }}
          />
          <div className="flex items-center ">
            <div className="flex items-center">
              <button
                className="w-[30px] h-[30px] rounded-full border solid border-[#E6E9EF] flex justify-center items-center hover:border-black duration-150 p-1"
                onClick={addMessage}
              >
                <Send fontSize="small" htmlColor="#555252" />
              </button>
            </div>
          </div>
        </div>
      </Popover>
      <ConfirmationModal
        isOpen={isOpen}
        onClose={(e: React.MouseEvent<HTMLButtonElement>) => {
          e.cancelable = true;
          e.stopPropagation();
          setIsOpen(false);
        }}
        action={(e: React.MouseEvent<HTMLButtonElement>) => {
          e.cancelable = true;
          e.stopPropagation();
          archiveChat();
          setIsOpen(false);
        }}
        actionName={t('Archive')}
        title={t('ARCHIVE_THIS_DISCUSSION')}
        message={t('ARCHIVE_THE_CURRENT_CHAT')}
        sensitivity="Destructive"
      />
    </div>
  );
};

export default DiscussionPopover;
